// src/components/Combat/CombatWide.tsx

import React, { useEffect, useState } from 'react';
import { eventCloud } from '../../eventCloud';
import { useParams, useNavigate } from 'react-router-dom';
import { Combat, Fighter, Round } from '../../store/types/fighterTypes'; // Adjust the import path as needed
import FighterProfile from '../Fighter/FighterProfile';
import FighterProfileHorizontal from '../Fighter/FighterProfileHorizontal';
import { CombatLog } from '../Combat/CombatLog';
import { CombatTape } from '../Combat/CombatTape';
import { CombatAttackSelector } from '../Combat/CombatAttackSelector';
import { CombatWinner } from '../Combat/CombatWinner';
import { CombatAbandoned } from '../Combat/CombatAbandoned';
import { CombatPrefightScreen } from '../Combat/CombatPrefightScreen';
import { CombatDraw } from '../Combat/CombatDraw';
import { CombatSpectator } from '../Combat/CombatSpectator';

import classNames from 'classnames';
import { Button } from '../Button/Button';
import '../../css/Combat.css';

import { ReactComponent as CheckedIcon } from '../../assets/icons/svg/checked.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/icons/svg/unchecked.svg';
import { ReactComponent as HealthIcon } from '../../assets/icons/svg/health.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';

const CombatWide: React.FC<{
	combat: Combat | null;
	closedRound: Round | null;
	fighter: Fighter | null;
	handleSendAttack: any;
}> = ({ combat, closedRound, fighter, handleSendAttack }) => {
	const navigate = useNavigate();
	// const [combat, setCombat] = useState<Combat | null>(null);
	// const [closedRound, setClosedRound] = useState<Round | null>(null);
	const [hitScreenVisible, setHitScreenVisible] = useState(false);

	const [selectedAttack, setSelectedAttack] = useState<string>('');
	const [selectedDefence, setSelectedDefence] = useState<string>('');
	const [selectedSkill, setSelectedSkill] = useState<string>('Basic');
	const [autoHit, setAutoHit] = useState(localStorage.getItem('combats.autoHit') == 'true' || false);

	useEffect(() => {
		if (!closedRound) return;

		setSelectedAttack('');
		setSelectedDefence('');
	}, [closedRound]);

	useEffect(() => {
		setSelectedSkill("Basic");
		if (fighter && fighter.skills && fighter.skills.length > 0) {
			for (const index in fighter.skills) {
				if (fighter.skills[index] && fighter.intelligence >= fighter.skills[index].required_int && fighter.skills[index].mana_cost <= fighter.mana) {
					setSelectedSkill(fighter.skills[index].name);
				}
			}
		}

		if (combat?.closed && autoHit) {
			setTimeout(() => {
				navigate('/profile');
			}, 5000);			
		}
	}, [combat]);

	useEffect(() => {
		console.log("Active round updated", combat?.active_round)
		if (!combat?.active_round || combat?.closed || !autoHit || isAttackSubmitted()) return;

		const attackRegion = getRandomRegion()
		const defenceRegion = getRandomRegion()

		

		setTimeout(() => {
			setSelectedAttack(attackRegion)
			setSelectedDefence(defenceRegion)
			handleSendAttack(attackRegion, defenceRegion, selectedSkill);
		}, 2000);
	}, [combat?.active_round, autoHit]);


	const isAttackSubmitted = (): boolean => {
		if (!combat || !combat.active_round) return false;
		//console.log("[isAttackSubmitted]",eventCloud.selectedFighter, combat.taker.id, combat.active_round.taker_submitted )

		if (combat.maker_id == eventCloud.selectedFighter?.id && combat.active_round.maker_submitted) {
			//console.log("[isAttackSubmitted] true")
			return true;
		}

		if (combat.taker_id == eventCloud.selectedFighter?.id && combat.active_round.taker_submitted) {
			//console.log("[isAttackSubmitted] true")
			return true;
		}

		return false;
	};

	function getRandomRegion() {
	    const regions = ['Head', 'Chest', 'Legs'];
	    return regions[Math.floor(Math.random() * regions.length)];
	}

	const toggleAutoHit = () => {
		const newAutoHit = !autoHit;
		setAutoHit(newAutoHit);

		localStorage.setItem('combats.autoHit', newAutoHit ? 'true' : 'false');
	};

	if (!combat) {
		return null;
	}

	const getWager = (): number => {
		return Math.min(combat.maker_wager, combat.taker_wager);
	};

	return (
		<div className="text-base flex flex-row  gap-y-2 gap-x-2">
			<div className="relative flex-grow w-1/3 max-h-full">
				<FighterProfile
					showManaNumber={true}
					showHealthNumber={true}
					hideStats={true}
					hideName={false}
					showItems={true}
					showItemLevels={true}
					fighter={fighter?.id === combat.taker_id ? combat.taker : combat.maker}
					allowItemPopover={fighter?.id === combat.maker_id || fighter?.id === combat.taker_id}
					equippable={false}
					onDefenceRegionSelect={(region: string) => {
						setSelectedDefence(region);
						if (selectedAttack != '') {
							handleSendAttack(selectedAttack, region, selectedSkill);
						}
					}}
					combat={combat}
					selectedDefence={selectedDefence}
					closedRound={closedRound}
					autoHit={autoHit}
					fighterSide={fighter?.id === combat.taker_id ? 'taker' : 'maker'}
				/>

				{combat.closed &&
					combat.winner &&
					(() => {
						const sideFighter = fighter?.id === combat.taker_id ? combat.taker : combat.maker;
						const isWinner =
							sideFighter.id === combat.maker_id
								? combat.maker_id === combat.winner.id
								: combat.taker_id === combat.winner.id;

						return (
							<div
								className={classNames(
									`absolute ${isWinner ? 'bg-blue-550' : 'bg-red-550'} bg-opacity-25 top-0 left-0 bottom-0 right-0 flex justify-center items-center text-xxl lg:text-4xl xl:text-5xl font-bold`
								)}
							>
								<div className={classNames(`text-white`)}>{isWinner ? 'WINNER' : 'LOSER'}</div>
							</div>
						);
					})()}
			</div>
			{/* Top Section */}
			<div className="flex-grow">
				<div className="flex gap-x-2 h-full">
					<div className="mt-8">
						<CombatTape
							side={fighter?.id === combat.taker_id ? 'taker' : 'maker'}
							combat={combat}
							className="flex-grow min-h-0"
						/>
					</div>
					<div className="gap-y-2 flex flex-col flex-grow h-full xl:min-w-[500px]">
						<div
							className="h-[22px] text-center flex justify-center items-center bg-cover bg-center w-full"
							style={{
								backgroundImage: "url('/assets/--combat-opponent-submitted.png')",
							}}
						>
							<div className="text-sm text-red-550 font-bold">
								ROUND {!combat.closed_rounds ? 1 : combat.closed_rounds.length + 1}
							</div>
						</div>
						<div
							className="h-[22px] text-center flex justify-center items-center bg-cover bg-center w-full"
							style={{
								backgroundImage: "url('/assets/--combat-claim-bg.png')",
							}}
						>
							<div className="text-sm text-green-500 font-bold">BET {getWager()} CR</div>
						</div>
						{/* CombatAttackSelector */}
						<div className="w-full h-full">
							{!combat.closed && !combat.active_round ? (
								<CombatPrefightScreen combat={combat} />
							) : !combat.closed ? (
								<CombatAttackSelector
									onSendAttack={() => {
										handleSendAttack(selectedAttack, selectedDefence, selectedSkill);
									}}
									selectedSkill={selectedSkill}
									setSelectedSkill={setSelectedSkill}
									combat={combat}
									autoHit={autoHit}
									toggleAutoHit={toggleAutoHit}
								/>
							) : combat.winner ? (
								<CombatWinner combat={combat} />
							) : combat.is_abandoned ? (
								<CombatAbandoned combat={combat} />
							) : (
								<CombatDraw combat={combat} />
							)}
						</div>
					</div>
					<div className="mt-8">
						<CombatTape
							side={fighter?.id === combat.taker_id ? 'maker' : 'taker'}
							combat={combat}
							className="flex-grow min-h-0"
						/>
					</div>
				</div>
			</div>

			<div className="relative flex-grow w-1/3">
				<FighterProfile
					showManaNumber={true}
					showHealthNumber={true}
					hideStats={true}
					hideName={false}
					showItems={true}
					showItemLevels={true}
					fighter={fighter?.id === combat.taker_id ? combat.maker : combat.taker}
					equippable={false}
					onAttackRegionSelect={(region: string) => {
						setSelectedAttack(region);
						if (selectedDefence != '') {
							handleSendAttack(region, selectedDefence, selectedSkill);
						}
					}}
					combat={combat}
					autoHit={autoHit}
					selectedAttack={selectedAttack}
					closedRound={closedRound}
					fighterSide={fighter?.id === combat.taker_id ? 'maker' : 'taker'}
				/>
				{combat.closed &&
					combat.winner &&
					(() => {
						const sideFighter = fighter?.id === combat.taker_id ? combat.maker : combat.taker;
						const isWinner =
							sideFighter.id === combat.maker_id
								? combat.maker_id === combat.winner.id
								: combat.taker_id === combat.winner.id;

						return (
							<div
								className={classNames(
									`absolute ${isWinner ? 'bg-blue-550' : 'bg-red-550'} bg-opacity-25 top-0 left-0 bottom-0 right-0 flex justify-center items-center text-xxl lg:text-4xl xl:text-5xl font-bold`
								)}
							>
								<div className={classNames(`text-white`)}>{isWinner ? 'WINNER' : 'LOSER'}</div>
							</div>
						);
					})()}
			</div>
		</div>
	);
};

export default CombatWide;
