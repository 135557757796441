// MarketRow.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { Button } from '../Button/Button';
import { Item, MarketItem, Fighter } from '../../store/types/fighterTypes';

import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';

type MarketRowProps = {
	marketItem: MarketItem;
	fighter: Fighter | null | undefined;
	handleUnlist?: (id: string) => void;
	handleBuyMarketItem?: (item: MarketItem) => void;
	onClick: (e: any) => void;
};

export const MarketRow = forwardRef<HTMLDivElement, MarketRowProps>(
	({ marketItem, fighter, handleUnlist, handleBuyMarketItem, onClick, ...rest }, ref) => {
		return (
			<tr className="text-white text-xxxs sm:text-xs font-regular bg-gray-550 bg-opacity-50 border-t border-black">
				<td className="text-left p-2">
					<div className="flex items-center space-x-2">
						<div className="w-[50px] sm:w-[96px]">
							<ItemThumbnail onClick={onClick} item={marketItem.item} />
						</div>
						<div>
							<div
								className={classNames(
									`font-bold text-xxxs sm:text-m ${(marketItem.item.has_luck || marketItem.item.has_skill) && 'text-blue-550'}`
								)}
							>
								{marketItem.item.name}
								{marketItem.item.level_upgradeable && ` +${marketItem.item.level}`}
								{marketItem.item.has_skill && ` +Skill`}
								{marketItem.item.has_luck && ` +Luck`}
							</div>
							<div className="text-blue-550">{marketItem.item.category}</div>
						</div>
					</div>
				</td>
				{/*<td className="text-left px-2 text-gray-200 w-[200px]">
					{formatTimestampToLocaleTime(marketItem.listed_at)}
				</td>*/}
				<td className="text-left px-2 w-[120px] space-x-2">
					<div className="flex space-x-1">
						<div className=" self-end">{marketItem.owner_name}</div>

						{marketItem.owner_id == fighter?.id && (
							<div className="text-gray-200 font-regular text-xs self-end">(YOU)</div>
						)}
					</div>
				</td>
				<td className="text-right px-2 w-[160px]">
					<div className="flex space-x-1 items-center">
						<div>
							<CreditsIcon className="w-[10px] sm:w-[20px]" />
						</div>
						<div className="font-bold text-blue-550">{formatNumLocale(marketItem.price, 0)}</div>
					</div>
				</td>
				<td className="text-right px-2 w-[100px]">
					{handleUnlist && marketItem.owner_address.toLowerCase() == fighter?.user_address?.toLowerCase() ? (
						<Button
							variant="black"
							className="px-1 text-xxs sm:text-m sm:px-2"
							onClick={() => handleUnlist(marketItem.item.id)}
						>
							UNLIST
						</Button>
					) : (
						<>
							{handleBuyMarketItem && (
								<Button
									className="px-1 text-xxs sm:text-m sm:px-2"
									onClick={() => handleBuyMarketItem(marketItem)}
								>
									BUY
								</Button>
							)}
						</>
					)}
				</td>
			</tr>
		);
	}
);

const formatNumLocale = (price: number, decimals: number): string => {
	return new Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(price);
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		day: '2-digit',
		month: '2-digit',
		year: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

MarketRow.displayName = 'MarketRow';
