// FighterStatsVertical.tsx

import React from 'react';
import { Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as StrengthIcon } from '../../assets/icons/svg/strength.svg';
import { ReactComponent as AgilityIcon } from '../../assets/icons/svg/agility.svg';
import { ReactComponent as IntelligenceIcon } from '../../assets/icons/svg/intelligence.svg';
import { ReactComponent as VitalityIcon } from '../../assets/icons/svg/vitality.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/svg/minus.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';

// Define the props interface
interface FighterStatsVerticalProps {
	fighter: Fighter | null;
	updateable?: boolean;
	vertical?: boolean;
}

const FighterStatsVertical: React.FC<FighterStatsVerticalProps> = ({ fighter, updateable, vertical }) => {
	//console.log("fighter", fighter)
	if (!fighter) {
		return <div>No fighter selected</div>;
	}

	const handleUnequipItem = (slot: string) => {
		eventCloud.unequipItem(slot);
	};

	const handleIncrementStrength = () => {
		eventCloud.incrementStrength();
	};

	const handleIncrementAgility = () => {
		eventCloud.incrementAgility();
	};

	const handleIncrementIntelligence = () => {
		eventCloud.incrementIntelligence();
	};

	const handleIncrementVitality = () => {
		eventCloud.incrementVitality();
	};

	const handleDecrementStrength = () => {
		eventCloud.decrementStrength();
	};

	const handleDecrementAgility = () => {
		eventCloud.decrementAgility();
	};

	const handleDecrementIntelligence = () => {
		eventCloud.decrementIntelligence();
	};

	const handleDecrementVitality = () => {
		eventCloud.decrementVitality();
	};

	return (
		<div className="flex flex-col gap-y-1 select-none w-full text-white">
			<div>
				<div className="flex justify-between w-full text-gray-200 py-1 items-center text-xxs">
					<div>Available points</div>
					<div className="font-bold text-blue-550">{fighter.available_stats}</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<StrengthIcon width={12} height={12} />
						<div className="">Strength </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementStrength}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.strength}

						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementStrength}
								/>
							</div>
						)}
					</div>
				</div>

				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Phys Damage </div>
					<div className="font-bold flex items-center gap-x-2">
						{fighter.min_damage}-{fighter.max_damage}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Critical Damage </div>
					<div className="font-bold flex items-center gap-x-2">{fighter.critical_damage_points}%</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<AgilityIcon width={12} height={12} />
						<div className="">Agility </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementAgility}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.agility}
						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementAgility}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Defence </div>
					<div className="font-bold flex items-center gap-x-2">{fighter.defence}</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<IntelligenceIcon width={12} height={12} />
						<div className="">Intelligence </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementIntelligence}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.intelligence}
						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementIntelligence}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Wiz Damage </div>
					<div className="font-bold flex items-center gap-x-2">
						{fighter.min_wiz_damage}-{fighter.max_wiz_damage}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Max Mana </div>
					<div className="font-bold flex items-center gap-x-2">{Math.floor(fighter.max_mana)}</div>
				</div>

				<div className="flex justify-between py-1 items-center text-xxs">
					<div className="flex items-center gap-x-1">
						<VitalityIcon width={12} height={12} />
						<div className="">Vitality </div>
					</div>
					<div className="font-bold flex items-center gap-x-1">
						<div
							className="bg-blue-550 rounded-sm p-0.5 text-black cursor-pointer relative active:top-px"
							onClick={handleDecrementVitality}
						>
							<MinusIcon className="cursor-pointer" width={8} height={8} />
						</div>
						{fighter.vitality}
						{fighter.available_stats > 0 && (
							<div>
								<PlusIcon
									className="cursor-pointer relative active:top-px"
									width={12}
									height={12}
									onClick={handleIncrementVitality}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between py-1 items-center text-gray-200  text-xxs">
					<div className="">Max HP </div>
					<div className="font-bold flex items-center gap-x-2">{Math.floor(fighter.max_health)}</div>
				</div>
			</div>
		</div>
	);
};

export default FighterStatsVertical;
