import React, { useEffect, useState, useRef } from 'react';
import { Chat } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { ReactComponent as SendMessageIcon } from '../../assets/icons/svg/send-message.svg';
import classNames from 'classnames';

interface ChatBoxProps {
	room_id: string;
	hide_members?: boolean;
	in_combat?: boolean;
	member?: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({ room_id, hide_members, in_combat, member }) => {
	const [chat, setChat] = useState<Chat | null>(null);
	const messageEndRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const chatsSub = eventCloud.chatBoxesStore.subscribe((newChats) => {
			const chat = newChats[room_id];
			if (chat) {
				setChat(chat);
			}
		});

		return () => {
			chatsSub.unsubscribe();
		};
	}, [room_id]);

	useEffect(() => {
		if (chat?.messages) {
			scrollToBottom(false);
		}
	}, [chat?.messages]);

	const handleSendChatMessage = (message: string) => {
		console.warn('[handleSendChatMessage] ', message, chat);
		if (chat && message.trim() !== '') {
			eventCloud.sendChatMessage(room_id, message);
		}
	};

	const scrollToBottom = (smooth = true) => {
		if (messageEndRef.current) {
			messageEndRef.current.scrollIntoView({
				behavior: smooth ? 'smooth' : 'auto',
			});
		}
	};

	return (
		<div className="h-full flex flex-col">
			<div className="flex-grow flex justify-between overflow-hidden">
				{/* Chat Messages Section */}
				<div className={`border-gray-540 border flex flex-col ${hide_members ? 'w-full' : 'w-2/3'}`}>
					<div className="text-xxxs md:text-xs font-bold px-1 md:px-4 py-1 md:py-2 border-b border-gray-540">
						CHAT
					</div>
					{/* The message container needs to have flex-grow and overflow-y-auto */}
					<div className="flex-grow overflow-y-auto">
						<div className="flex flex-col h-full">
							{chat?.messages?.map((msg, index) => (
								<div
									key={index}
									className={classNames('flex px-1 md:px-4 items-center py-0.5 md:py-1', {
										'bg-gray-550': member === msg.author.author_id,
										'text-blue-550': msg.author.name === 'admin',
									})}
								>
									{/*<div className="text-xxxs md:text-sm text-gray-200 pr-1 md:pr-2 font-mono border">
										{formatTimestampToLocaleTime(msg.created_at)}{' '}
									</div>*/}
									<div className="font-regular text-xxxs md:text-sm">
										<span
											className={`font-bold ${member === msg.author.author_id && 'text-blue-550'}`}
										>
											{msg.author.name}
										</span>{' '}
										<span
											dangerouslySetInnerHTML={{
												__html: convertLinksToClickable(msg.message),
											}}
										/>
									</div>
								</div>
							))}
							<div ref={messageEndRef} />
						</div>
					</div>
				</div>

				{/* Members List Section */}
				{!hide_members && (
					<div className="w-1/3 border-r border-t border-b border-gray-540 flex flex-col">
						<div className="text-xxxs md:text-xs font-bold px-1 md:px-4 py-1 md:py-2 border-b border-gray-540">
							ONLINE <span className="text-blue-550">{chat?.members.length}</span>
						</div>
						<div className="flex-grow mt-2 overflow-y-auto text-m">
							{chat?.member_fighters?.map((memberFighter, index) => (
								<div key={index} className="flex px-4 items-center font-bold py-1">
									<div
										className={classNames(
											`pr-2 ${memberFighter.id === member ? 'text-blue-550' : 'text-white'}`
										)}
									>
										{memberFighter.name}{' '}
										<span className="text-blue-550">[{memberFighter.level}]</span>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>

			{/* Input Section */}
			<form
				onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
					e.preventDefault();
					const messageInput = e.currentTarget.elements.namedItem('message') as HTMLInputElement;
					handleSendChatMessage(messageInput.value);
					messageInput.value = ''; // Clear input after sending
				}}
			>
				<div className="border-b border-l border-r flex justify-between border-gray-540 items-center">
					<div className="w-full flex">
						<input
							className="w-full px-1 md:px-4 py-1 md:py-2 bg-black text-white placeholder-gray-200 text-xxxs md:text-xs outline-none"
							placeholder="Enter message"
							type="text"
							name="message"
							autoComplete="off"
						/>
					</div>
					<div
						className="relative active:top-px items-center px-1 md:px-2 py-1 md:py-2 "
						onClick={(e) => {
							e.preventDefault();
							const form = e.currentTarget.closest('form') as HTMLFormElement;
							const messageInput = form.elements.namedItem('message') as HTMLInputElement;
							handleSendChatMessage(messageInput.value);
							messageInput.value = ''; // Clear input after sending
						}}
					>
						<SendMessageIcon className="w-[12px] h-[12px] md:h-[16px] md:w-[16px]" />
					</div>
				</div>
			</form>
		</div>
	);
};

function convertLinksToClickable(text: string) {
	// Regular expression to match URLs
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, (url: string) => {
		return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #FFB770; "><u>${url}</u></a>`;
	});
}

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		hour: '2-digit',
		minute: '2-digit',
	});
}

export default ChatBox;
