// Inventory.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Account, Fighter, Item } from '../../store/types/fighterTypes';
import './Inventory.css'; // Import CSS module or styles
import { eventCloud } from '../../eventCloud';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import { ItemPopover } from '../Item/ItemPopover';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { Tabs } from '../Shared/Tabs';
import Modal from 'react-modal';
import { addItem, removeItem, updateItem, setInventory } from '../../store/reducers/inventoryReducer';

import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as DownArrow } from '../../assets/icons/svg/down-arrow.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as UpgradeIcon } from '../../assets/icons/svg/upgrade.svg';
import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';

interface InventoryProps {
	fighter: Fighter | null;
	account: Account | null;
}

const Inventory: React.FC<InventoryProps> = ({ account, fighter }) => {
	const dispatch = useDispatch();
	const inventory = useSelector((state: any) => state.inventory.inventory);

	const [isListingModalOpen, setIsListingModalOpen] = useState(false);
	const [listingItemId, setListingItemId] = useState<string | null>(null);
	const [listingPrice, setListingPrice] = useState<number | string>('');
	const [itemToList, setItemToList] = useState<Item | null>(null);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Item | null>(null);
	const [selectedTab, setSelectedTab] = useState('All');

	useEffect(() => {
		if (account?.inventory) {
			dispatch(setInventory(account.inventory));
		} else {
			dispatch(setInventory([]));
		}
	}, [account, dispatch]);

	const handleEquipItem = (id: string) => {
		eventCloud.equipItem(id);
	};

	// const handleConsumeItem = (id: string) => {
	// 	eventCloud.consumeItem(id);
	// };

	const handleUpgradeItemLevel = (id: string) => {
		eventCloud.upgradeItemLevel(id);
	};

	const handleListToMarket = (id: string | undefined, price: number | undefined) => {
		if (!id || !price) return;
		eventCloud.listToMarket(id, price);
	};

	const handleLiquidateItem = (item_id: string) => {
		eventCloud.liquidateItem(item_id);
	};

	const handleUnlist = (item_id: string) => {
		eventCloud.unlistFromMarket(item_id);
	};

	if (inventory.length == 0) {
		return <div className="flex justify-center p-2 text-gray-200 text-xxs xs:text-sm">Inventory Empty</div>;
	}

	return (
		<div className="">
			<div className="flex flex-col h-full">
				{/*<Tabs
					items={['All', 'Weapon', 'Armor']}
					onSelect={(tab: string) => {
						setSelectedTab(tab);
					}}
				/>*/}
				<div className="flex-grow md:overflow-y-auto h-[calc(100vh-12rem)]">
					<div className="flex flex-wrap gap-2">
						{inventory
							?.filter(
								(item: Item) =>
									selectedTab == 'All' ||
									item.category === selectedTab ||
									(selectedTab == 'Listed' && item.is_listed)
							)
							.map((item: Item, index: number) => (
								<div key={index} className="max-w-[calc(25%-0.4rem)] sm:max-w-[94px]">
									<ItemThumbnail
										key={index}
										item={item}
										onClick={(e: any) => {
											setAnchorEl(e.currentTarget);
											setSelectedItem(item);
										}}
									/>
								</div>
							))}
					</div>
				</div>
				<ItemPopover
					selectedItem={selectedItem}
					anchorEl={anchorEl}
					handleUpgradeItemLevel={handleUpgradeItemLevel}
					handleEquipItem={handleEquipItem}
					handleLiquidateItem={handleLiquidateItem}
					handleUnlist={handleUnlist}
					// handleConsumeItem={handleConsumeItem}
					setItemToList={(item: Item) => {
						setItemToList(item);
					}}
					setIsListingModalOpen={(val: boolean) => {
						setIsListingModalOpen(val);
					}}
					fighter={fighter}
					account={account}
					onClose={() => {
						setSelectedItem(null);
						setAnchorEl(null);
					}}
				/>

				{/* Listing Modal */}
				<Modal
					isOpen={isListingModalOpen}
					onRequestClose={() => {
						setIsListingModalOpen(false);
						setListingPrice('');
					}}
					contentLabel="List item on the market"
					className="w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540"
					overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
				>
					{/* Modal Content */}
				</Modal>
			</div>

			{/* Listing Modal */}
			<Modal
				isOpen={isListingModalOpen}
				onRequestClose={() => {
					setIsListingModalOpen(false);
					setListingPrice('');
				}}
				contentLabel="List item on the market"
				className="w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-4">
					<div className="flex-grow">
						<span className="font-regular text-xs mb-4">LIST ITEM TO MARKETPLACE</span>
					</div>
					<div
						onClick={() => {
							setIsListingModalOpen(false);
							setListingPrice('');
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div>
					<div className="text-sm text-gray-200 mb-2">
						<div className="flex mb-4">
							<div>
								<img
									className="rounded-sm border border-gray-540"
									src={`/assets/${itemToList?.name}.jpg`}
									width={66}
									height={100}
									alt={itemToList?.name}
								/>
							</div>
							<div className="p-4">
								<div className="font-bold text-base text-white">
									{itemToList?.name} +{itemToList?.level}
								</div>
								<div className="text-blue-550 text-m">{itemToList?.item_type}</div>
							</div>
						</div>
						<div className="text-xs mb-2">ASKING PRICE</div>
						<div className="relative">
							<input
								type="text"
								value={listingPrice}
								onChange={(e) => setListingPrice(e.target.value === '' ? '' : Number(e.target.value))}
								placeholder="0"
								className="w-full px-10 py-3 rounded-sm text-xs placeholder-gray-200 bg-gray-550 font-bold text-blue-550 appearance-none outline-none"
							/>
							<CreditsIcon
								className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-200"
								width={18}
								height={18}
							/>
						</div>

						<div className="mt-4">
							<div className="flex text-m items-center">
								Listing Fee:
								<CreditsIcon width={18} height={18} className="ml-2" />
								<span className="text-blue-550 font-bold ml-1">100</span>
							</div>
							{/*<div className="flex text-m items-center">
								Listing Duration:
								<span className="text-white ml-1">10 days</span>
							</div>*/}
						</div>
					</div>
					<div className="mt-8">
						<Button
							variant="black"
							size="large"
							onClick={() => {
								handleListToMarket(itemToList?.id, Number(listingPrice));
								setIsListingModalOpen(false);
								setListingPrice('');
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Inventory;
