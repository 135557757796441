// Market.tsx

import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Item, MarketItem, Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { getBackendUrl } from '../../helpers/getBackendUrl';
import { Tabs } from '../Shared/Tabs';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { ItemPopover } from '../Item/ItemPopover';
import { Pagination } from '../Pagination/Pagination';
import { Button } from '../Button/Button';
import { MarketRow } from '../Market/MarketRow';
import { MarketHistoryRow } from '../Market/MarketHistoryRow';
import classNames from 'classnames';
import Popover from '@mui/material/Popover';
import Modal from 'react-modal';

import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/svg/down-arrow.svg';

type MarketProps = {
	fighter?: Fighter | null;
};

export const Market = forwardRef<HTMLDivElement, MarketProps>(({ fighter, ...rest }, ref) => {
	const [marketItems, setMarketItems] = useState<MarketItem[] | null>(null);
	const [totalPages, setTotalPages] = useState(0);
	const [selectedTab, setSelectedTab] = useState('Listings');
	const [historyItems, setHistoryItems] = useState<MarketItem[] | null>(null);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [classAnchorEl, setClassAnchorEl] = useState<HTMLElement | null>(null);
	const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
	const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null);

	const [selectedItem, setSelectedItem] = useState<Item | null>(null);
	const [currentPage, setCurrentPage] = useState(1);

	const [itemToBuy, setItemToBuy] = useState<MarketItem | null>(null);
	const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);

	const filterClass = useRef<number | null>(null);
	const filterCategory = useRef<string | null>(null);
	const sortCriteria = useRef<string | null>('Newest');

	// Setting a value:
	const setFilterClass = (value: number | null) => {
		filterClass.current = value;
	};

	const setFilterCategory = (value: string | null) => {
		filterCategory.current = value;
	};

	const setSortCriteria = (value: string | null) => {
		sortCriteria.current = value;
	};

	useEffect(() => {
		if (selectedTab === 'Listings') {
			// Fetch market items from the backend API endpoint '/market'
			fetchPage(currentPage);
		}
	}, [selectedTab]);

	useEffect(() => {
		const sub = eventCloud.marketHistoryStore.subscribe((items) => {
			setHistoryItems(items);
		});

		const handleMarketUpdated = () => {
			console.log('handleMarketUpdated');
			fetchPage(currentPage);
		};

		eventCloud.on('market_update', handleMarketUpdated);

		// Cleanup subscription on unmount
		return () => {
			sub.unsubscribe();
			eventCloud.off('market_update', handleMarketUpdated);
		};
	}, []);

	const fetchPage = (num: number) => {
		fetch(
			getBackendUrl() +
				'/market?sort=' +
				sortCriteria.current +
				'&class=' +
				filterClass.current +
				'&category=' +
				filterCategory.current +
				'&page=' +
				num
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch market items');
				}
				return response.json();
			})
			.then((data: any) => {
				setMarketItems(data.items);
				setTotalPages(data.total_pages);
				setCurrentPage(num);
			})
			.catch((error) => {
				console.error('Error fetching market items:', error);
			});
	};

	const handleBuyMarketItem = (item: MarketItem) => {
		setItemToBuy(item);
		setIsBuyModalOpen(true);
	};

	const handleDoBuyMarketItem = (id: string | undefined) => {
		if (!id) return;
		console.log('[handleBuyMarketItem] ', id);
		eventCloud.buyMarketItem(id);
	};

	const handleTabClick = (tab: string) => {
		setSelectedTab(tab);
		if (tab === 'History') {
			eventCloud.requestMarketHistory();
		}
	};

	const handleUnlist = (item_id: string) => {
		eventCloud.unlistFromMarket(item_id);

		setTimeout(() => {
			fetchPage(currentPage);
		}, 100);
	};

	return (
		<div ref={ref} className="w-full flex-grow">
			<Modal
				isOpen={isBuyModalOpen}
				onRequestClose={() => {
					setIsBuyModalOpen(false);
				}}
				contentLabel="List item on the market"
				className="text-xxs sm:text-xs fixed bottom-0 sm:bottom-auto w-full sm:w-[400px] mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-4">
					<div className="flex-grow">
						<span className="font-regular mb-4">CONFIRM ITEM PURCHASE</span>
					</div>
					<div
						onClick={() => {
							setIsBuyModalOpen(false);
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div className="flex flex-col gap-y-2">
					<div
						className={classNames(
							`flex justify-center font-bold text-xxxs sm:text-m ${(itemToBuy?.item.has_luck || itemToBuy?.item.has_skill) && 'text-blue-550'}`
						)}
					>
						{itemToBuy?.item.name}
						{itemToBuy?.item.level_upgradeable && ` +${itemToBuy?.item.level}`}
						{itemToBuy?.item.has_skill && ` +Skill`}
						{itemToBuy?.item.has_luck && ` +Luck`}
					</div>
					<div className=" flex justify-center">
						<div className="w-1/2">
							<ItemThumbnail item={itemToBuy ? itemToBuy.item : null} />
						</div>
					</div>
					<div className="flex justify-center items-center">
						<CreditsIcon />
						<div className="font-bold text-blue-550">{formatNumLocale(Number(itemToBuy?.price), 0)}</div>
					</div>
				</div>
				<div>
					<div className="mt-8">
						<Button
							variant="basic"
							size="large"
							onClick={() => {
								setIsBuyModalOpen(false);
								handleDoBuyMarketItem(itemToBuy?.id);
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>
			<Popover
				open={Boolean(classAnchorEl)}
				anchorEl={classAnchorEl}
				onClose={() => {
					setClassAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: 0,
					},
				}}
			>
				<div className="text-xs bg-black text-white p-2 flex flex-col gap-y-1 cursor-pointer select-none">
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterClass(null);
							setCurrentPage(1);
							fetchPage(1);
							setClassAnchorEl(null);
						}}
					>
						All
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterClass(0);
							setCurrentPage(1);
							fetchPage(1);
							setClassAnchorEl(null);
						}}
					>
						Knight
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterClass(1);
							setCurrentPage(1);
							fetchPage(1);
							setClassAnchorEl(null);
						}}
					>
						Wizard
					</div>
				</div>
			</Popover>

			<Popover
				open={Boolean(categoryAnchorEl)}
				anchorEl={categoryAnchorEl}
				onClose={() => {
					setCategoryAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: 0,
					},
				}}
			>
				<div className="text-xs bg-black text-white p-2 flex flex-col gap-y-1 cursor-pointer select-none">
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory(null);
							setCategoryAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						All
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory('Armor');
							setCategoryAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Armor
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory('Weapon');
							setCategoryAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Weapon
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory('Jewel');
							setCategoryAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Jewel
					</div>
				</div>
			</Popover>

			<Popover
				open={Boolean(sortAnchorEl)}
				anchorEl={sortAnchorEl}
				onClose={() => {
					setSortAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: 0,
					},
				}}
			>
				<div className="text-xs bg-black text-white p-2 flex flex-col gap-y-1 cursor-pointer select-none">
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setSortCriteria('Newest');
							setSortAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Newest
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setSortCriteria('Oldest');
							setSortAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Oldest
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setSortCriteria('Price Highest');
							setSortAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Price Highest
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setSortCriteria('Price Lowest');
							setSortAnchorEl(null);
							setCurrentPage(1);
							fetchPage(1);
						}}
					>
						Price Lowest
					</div>
				</div>
			</Popover>

			<div>
				<Tabs
					items={['Listings', 'History']}
					variant="block"
					noLine
					onSelect={(tab: string) => {
						handleTabClick(tab);
					}}
				/>
			</div>

			<div className="text-m w-full overflow-x-auto">
				{selectedTab === 'Listings' && (
					<>
						<div className="text-xxs font-bold mb-2 flex justify-between">
							<div className="flex gap-x-2">
								<div className=" text-gray-200">Filter:</div>
								<div
									className="relative flex gap-x-1 items-center select-none cursor-pointer active:top-px"
									onClick={(e: any) => {
										setClassAnchorEl(e.currentTarget);
									}}
								>
									<div>
										{filterClass.current == null
											? 'Class'
											: filterClass.current == 0
												? 'Knight'
												: 'Wizard'}
									</div>
									<div>
										<ArrowIcon />
									</div>
								</div>
								<div
									className="relative flex gap-x-1 items-center select-none cursor-pointer active:top-px"
									onClick={(e: any) => {
										setCategoryAnchorEl(e.currentTarget);
									}}
								>
									<div>{filterCategory.current == null ? 'Category' : filterCategory.current}</div>
									<div>
										<ArrowIcon />
									</div>
								</div>
							</div>

							<div className="flex gap-x-2">
								<div className=" text-gray-200">Sort By:</div>
								<div
									className="relative flex gap-x-1 items-center select-none cursor-pointer active:top-px"
									onClick={(e: any) => {
										setSortAnchorEl(e.currentTarget);
									}}
								>
									<div>{sortCriteria.current}</div>
									<div>
										<ArrowIcon />
									</div>
								</div>
							</div>
						</div>
						<table className="w-full">
							<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold text-xxs border-t border-gray-550 h-[27px]">
								<tr>
									<th className="text-left px-2 min-w-[200px]">Item</th>
									{/*<th className="text-left px-2">Added</th>*/}
									<th className="text-left px-2">Seller</th>
									<th className="text-left px-2">Price</th>
									<th className="text-right px-2"></th>
								</tr>
							</thead>
							<tbody>
								{marketItems?.map((marketItem, index) => (
									<MarketRow
										key={index}
										marketItem={marketItem}
										handleUnlist={handleUnlist}
										handleBuyMarketItem={handleBuyMarketItem}
										fighter={fighter}
										onClick={(e) => {
											setAnchorEl(e.currentTarget);
											setSelectedItem(marketItem.item);
										}}
									/>
								))}

								<ItemPopover
									anchor="center"
									selectedItem={selectedItem}
									anchorEl={anchorEl}
									fighter={fighter}
									onClose={() => {
										setAnchorEl(null);
										setSelectedItem(null);
									}}
								/>
							</tbody>
						</table>

						<Pagination
							totalPages={totalPages}
							currentPage={currentPage}
							onChange={(page: number) => {
								fetchPage(page);
							}}
						/>
					</>
				)}

				{selectedTab === 'History' && (
					<>
						{!historyItems ? (
							<div className="flex justify-center p-10 text-gray-200 text-xxs sm:text-m">
								Your marketplace history is empty
							</div>
						) : (
							<table className="w-full">
								<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold text-xxxs sm:text-m border-t border-gray-550 h-[27px]">
									<tr>
										<th className="text-left px-2">Item</th>
										<th className="text-left px-2">Time</th>
										<th className="text-left px-2">Side</th>
										<th className="text-left px-2">Counterparty</th>
										<th className="text-left px-2">Price</th>
									</tr>
								</thead>
								<tbody>
									{historyItems?.map((marketItem, index) => (
										<MarketHistoryRow
											key={index}
											marketItem={marketItem}
											handleUnlist={handleUnlist}
											fighter={fighter}
										/>
									))}

									<ItemPopover
										anchor="center"
										selectedItem={selectedItem}
										anchorEl={anchorEl}
										fighter={fighter}
										onClose={() => {
											setAnchorEl(null);
											setSelectedItem(null);
										}}
									/>
								</tbody>
							</table>
						)}
					</>
				)}
			</div>
		</div>
	);
});

const formatNumLocale = (price: number, decimals: number): string => {
	return new Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(price);
};

Market.displayName = 'Market';
