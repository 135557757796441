// src/pages/ProfilePageNarrow.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventCloud } from '../eventCloud';
import { Fighter, Combat, Account } from '../store/types/fighterTypes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import classNames from 'classnames';
import {
	addActiveCombat,
	removeActiveCombat,
	addFinishedCombat,
	setActiveCombats,
	setFinishedCombats,
} from '../store/reducers/combatReducer';
import History from '../components/History/History';
import Inventory from '../components/Inventory/Inventory';
import { Shop } from '../components/Shop/Shop';
import { Tabs } from '../components/Shared/Tabs';
import FighterProfile from '../components/Fighter/FighterProfile';
import FighterStatsVertical from '../components/Fighter/FighterStatsVertical';
import SponsorIndicator from '../components/Shared/SponsorIndicator';
import SponsorPage from './SponsorPage';
import AffiliatePage from './AffiliatePage';
import { FighterPopover } from '../components/Fighter/FighterPopover';
import { Market } from '../components/Market/Market';
import Modal from 'react-modal';
import ChatBox from '../components/ChatBox/ChatBox';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';
import { ReactComponent as DefeatIcon } from '../assets/icons/svg/defeat.svg';
import { ReactComponent as DrawIcon } from '../assets/icons/svg/draw.svg';
import { ReactComponent as AbandonIcon } from '../assets/icons/svg/abandon.svg';
import { ReactComponent as IconSpinner } from '../assets/icons/svg/spinner.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/svg/close.svg';
import { ReactComponent as SponsorCrown } from '../assets/icons/svg/sponsor-crown.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/svg/profile.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/svg/history.svg';
import { ReactComponent as ShopIcon } from '../assets/icons/svg/shop.svg';
import { ReactComponent as MenuIcon } from '../assets/icons/svg/menu.svg';
import { ReactComponent as ReferrIcon } from '../assets/icons/svg/referr.svg';

import { ReactComponent as CheckedIcon } from '../assets/icons/svg/checked.svg';
import { ReactComponent as UncheckedIcon } from '../assets/icons/svg/unchecked.svg';
import { ReactComponent as RefreshIcon } from '../assets/icons/svg/refresh.svg';

type ProfilePageProps = {
	fighter: Fighter | null;
	account: Account | null;
};

const ProfilePageNarrow: React.FC<ProfilePageProps> = ({ fighter, account }) => {
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 575);
	const [selectedTab, setSelectedTab] = useState('');
	const [autoHit, setAutoHit] = useState(localStorage.getItem('combats.autoHit') == 'true' || false);

	const toggleAutoHit = () => {
		const newAutoHit = !autoHit;
		setAutoHit(newAutoHit);

		localStorage.setItem('combats.autoHit', newAutoHit ? 'true' : 'false');

		if (newAutoHit && fighter && fighter.health == fighter.max_health && fighter.mana == fighter.max_mana && !fighter.in_combat) {
			eventCloud.sendFight();
		}

	};

	useEffect(() => {
		if (autoHit == true && fighter && fighter.health == fighter.max_health && fighter.mana == fighter.max_mana && !fighter.in_combat) {
			setTimeout(() => {
				eventCloud.sendFight();
			}, 1000)
			
		}
	}, [fighter]);
						

	useEffect(() => {
		const handleResize = () => {
			setIsLargeScreen(window.innerWidth > 575);
		};
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleFight = () => {
		eventCloud.sendFight();
	};

	if (!fighter) {
		return (
			<div className="flex flex-col justify-center items-center min-h-[500px]  text-gray-200 text-base xs:text-2xl">
				<div className="h-full">
					<div className="text-center">No Character Selected</div>
					<div className="text-xs xs:text-lg text-center">Please select a character or create one.</div>
				</div>
			</div>
		);
	}

	return (
		<div className="h-full">
			<div className="flex flex-col h-full">
				<div className="order-1 xs:order-2 flex justify-center sm:w-[calc(30%)] mb-1 h-full">
					<div className="flex flex-col gap-y-2 w-full">
						
						<div className="flex justify-center">
							<div className="w-full">
								<Button
									onClick={handleFight}
									size="large"
									className="py-3 text-base"
									variant={fighter?.in_combat ? 'red' : 'basic'}
								>
									{fighter?.in_combat ? (
										<div className="flex justify-center gap-x-2">
											<IconSpinner width={16} />
											<div>SEARCHING OPPONENT</div>
										</div>
									) : (
										<span>FIGHT</span>
									)}
								</Button>
							</div>
						</div>
						<div
							className="flex bg-gray-550 text-xxs rounded-sm px-1 py-1 justify-between items-center gap-x-2 cursor-pointer select-none"
							onClick={toggleAutoHit}
						>
							<div className="flex gap-x-1 items-center">
								<RefreshIcon />
								Auto Fight
							</div>
							<div className="flex gap-x-1 items-center font-bold">
								{autoHit ? "On" : "Off"}
								{autoHit ? <CheckedIcon /> : <UncheckedIcon />}
							</div>
							
						</div>
						<div className="flex justify-center max-w-full max-h-[300px] sm:max-h-full gap-x-2">
							<div className="w-full overflow-x-auto flex flex-col gap-y-1">
								<FighterStatsVertical fighter={fighter} />
							</div>
							<FighterProfile
								hideHealthMana={true}
								equippable={true}
								allowItemPopover={true}
								fighter={fighter}
							/>
						</div>
						{/*<div className="flex flex-col gap-x-1 gap-y-1 w-full flex-grow min-h-[120px] h-0 overflow-y-auto mb-10">
							<div className="text-xs">News</div>
							<div className="text-xxs">
								<span className="text-blue-550">24 Dec</span>: Marketplace is now back
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">19 Dec</span>: Removed two staff bonus for wizard and
								returned weapon skill for knight
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">17 Dec</span>: Added stats decrease button. You can now
								edit the stats on the character.
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">15 Dec</span>: New bonuses for full sets (
								<a
									rel="noreferrer"
									className="text-blue-550"
									href="https://docs.combats.game/getting-started/set-bonuses"
									target="_blank"
								>
									read more
								</a>
								)
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">13 Dec</span>: New bonuses for two knight weapons based
								on weapon level (
								<a
									rel="noreferrer"
									className="text-blue-550"
									href="https://docs.combats.game/getting-started/character-classes/knight"
									target="_blank"
								>
									read more
								</a>
								)
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">13 Dec</span>: New bonuses for two wizard staffs based
								on weapon level (
								<a
									rel="noreferrer"
									className="text-blue-550"
									href="https://docs.combats.game/getting-started/character-classes/wizard"
									target="_blank"
								>
									read more
								</a>
								)
							</div>
							<div className="text-xxs">
								<span className="text-blue-550">10 Dec</span>: Removed weapon skill for the knight class
								to balance the Knight/Wizard power.
							</div>
						</div>*/}
						<div className="flex-grow min-h-[120px] h-0 mb-10">
							<ChatBox room_id="lobby0" hide_members={true} />
						</div>
						{/*<div>
							<div className="flex flex-wrap gap-x-1 gap-y-1 w-full justify-between">
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-blue-550 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-blue-550">
										<AttackIcon />
										Victories
									</div>
									<div className="text-xl md:text-2xl font-bold">{fighter?.victories}</div>
								</div>
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-red-550 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-red-550">
										<DefeatIcon />
										Defeats
									</div>
									<div className="text-xl md:text-2xl font-bold ">{fighter?.defeats}</div>
								</div>
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-yellow-500 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-yellow-500">
										<DrawIcon />
										Draws
									</div>
									<div className="text-xl md:text-2xl font-bold">{fighter?.draws}</div>
								</div>
								<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-gray-200 border rounded-sm p-2">
									<div className="flex gap-x-1 text-xxs md:text-m text-gray-200">
										<AbandonIcon />
										Abandons
									</div>
									<div className="text-xl md:text-2xl font-bold">{fighter?.abandons}</div>
								</div>
							</div>
						</div>*/}
						{/*<div>
							<SponsorIndicator fighter={fighter} />
						</div>*/}

						{/*<div className="flex flex-wrap gap-1">
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="purple"
									onClick={() => {
										setSelectedTab('INVENTORY');
									}}
								>
									INVENTORY
								</Button>
							</div>
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="yellow"
									onClick={() => {
										setSelectedTab('HISTORY');
									}}
								>
									HISTORY
								</Button>
							</div>
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="green"
									onClick={() => {
										setSelectedTab('SHOP');
									}}
								>
									SHOP
								</Button>
							</div>
							<div className="w-[calc(50%-0.15rem)]">
								<Button
									variant="gold"
									onClick={() => {
										setSelectedTab('VIP');
									}}
								>
									<div className="flex items-center justify-center text-yellow-550 gap-x-1">
										<SponsorCrown height={14} width={14} />
										VIP
									</div>
								</Button>
							</div>
							<div className="w-[calc(100%)]">
								<Button
									variant="black"
									onClick={() => {
										setSelectedTab('AFFILIATES');
									}}
								>
									<div className="flex items-center justify-center gap-x-1">REFER A FRIEND</div>
								</Button>
							</div>
						</div>*/}

						{/*<div className="text-xs text-center text-white bg-gray-550 p-1 mb-1">
							<a href="https://dmex.app" rel="noreferrer" target="_blank" className="hover:underline">
								<span className="text-purple-900 font-bold">AD:</span> Trade <b>crypto</b> futures with
								up to <span className="text-blue-550 font-bold">500x leverage</span> on{' '}
								<span className="text-yellow-500 font-bold">DMEX</span>
							</a>
						</div>*/}
					</div>
				</div>
			</div>

			<div className="fixed bottom-0 w-full -ml-2 bg-gray-550 flex p-2 text-xxs text-gray-200 justify-between">
				{/*				<div className="w-1/5 flex flex-col items-center gap-y-0.5 bg-blue-550 text-black rounded-sm">
					<ProfileIcon />
					Profile
				</div>*/}
				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5 pt-1"
					onClick={() => {
						setSelectedTab('INVENTORY');
					}}
				>
					<MenuIcon />
					Inventory
				</div>
				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5 "
					onClick={() => {
						setSelectedTab('HISTORY');
					}}
				>
					<HistoryIcon />
					History
				</div>

				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5"
					onClick={() => {
						setSelectedTab('SHOP');
					}}
				>
					<ShopIcon />
					Shop
				</div>

				{/*<div className="w-1/5 flex flex-col items-center gap-y-0.5 text-yellow-550">
					<SponsorCrown />
					VIP
				</div>*/}

				{/*				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5"
					onClick={() => {
						setSelectedTab('AFFILIATES');
					}}
				>
					<ReferrIcon />
					Referr
				</div>*/}

				<div
					className="relative active:top-px w-1/5 flex flex-col items-center gap-y-0.5"
					onClick={() => {
						setSelectedTab('MARKETPLACE');
					}}
				>
					<ReferrIcon />
					Marketplace
				</div>
			</div>

			<Modal
				isOpen={selectedTab === 'INVENTORY'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="INVENTORY"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>Inventory</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="h-full overflow-y-auto">
					<Inventory account={account} fighter={fighter} />
				</div>
			</Modal>
			<Modal
				isOpen={selectedTab === 'HISTORY'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="HISTORY"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>History</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto max-h-full">
					<History fighter={fighter} />
				</div>
			</Modal>
			<Modal
				isOpen={selectedTab === 'SHOP'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="SHOP"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>Shop</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto h-full">
					<Shop fighter={fighter} />
				</div>
			</Modal>

			<Modal
				isOpen={selectedTab === 'VIP'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="VIP"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>VIP</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto h-full">
					<SponsorPage fighter={fighter} />
				</div>
			</Modal>

			<Modal
				isOpen={selectedTab === 'AFFILIATES'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="AFFILIATES"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>REFER FRIENDS</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto max-h-[450px]">
					<AffiliatePage account={account} />
				</div>
			</Modal>

			<Modal
				isOpen={selectedTab === 'MARKETPLACE'}
				onRequestClose={() => {
					setSelectedTab('');
				}}
				contentLabel="MARKETPLACE"
				className="w-full h-full mx-auto p-4 bg-black ring-1 ring-gray-540 outline-none fixed bottom-0 sm:relative sm:w-auto"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div
					className="flex justify-between mb-2 items-center"
					onClick={() => {
						setSelectedTab('');
					}}
				>
					<div>MARKETPLACE</div>
					<div>
						<CloseIcon />
					</div>
				</div>
				<div className="overflow-y-auto h-full pb-10">
					<Market fighter={fighter} />
				</div>
			</Modal>
		</div>
	);
};

export default ProfilePageNarrow;
