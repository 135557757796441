// FighterStats.tsx

import React from 'react';
import { Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as StrengthIcon } from '../../assets/icons/svg/strength.svg';
import { ReactComponent as AgilityIcon } from '../../assets/icons/svg/agility.svg';
import { ReactComponent as IntelligenceIcon } from '../../assets/icons/svg/intelligence.svg';
import { ReactComponent as VitalityIcon } from '../../assets/icons/svg/vitality.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/svg/minus.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';

// Define the props interface
interface FighterStatsProps {
	fighter: Fighter | null;
	updateable?: boolean;
	vertical?: boolean;
}

const FighterStats: React.FC<FighterStatsProps> = ({ fighter, updateable, vertical }) => {
	//console.log("fighter", fighter)
	if (!fighter) {
		return <div>No fighter selected</div>;
	}

	const handleUnequipItem = (slot: string) => {
		eventCloud.unequipItem(slot);
	};

	const handleIncrementStrength = () => {
		eventCloud.incrementStrength();
	};

	const handleIncrementAgility = () => {
		eventCloud.incrementAgility();
	};

	const handleIncrementIntelligence = () => {
		eventCloud.incrementIntelligence();
	};

	const handleIncrementVitality = () => {
		eventCloud.incrementVitality();
	};

	return (
		<div className="flex flex-col space-y-1 select-none w-full">
			{vertical && (
				<div className="h-1/2 flex justify-between space-x-1">
					<div className="w-1/2  border border-gray-550 text-m rounded-sm">
						<div className="flex px-auto flex-grow text-gray-200 items-center">
							<div
								className={`flex mx-auto items-center space-x-1 ${vertical && 'p-2'}`}
								data-tooltip-id="attack-tooltip"
								data-tooltip-content="Min Damage - Max Damage"
							>
								<div>
									<AttackIcon width={vertical ? 20 : 15} height={vertical ? 20 : 15} />
								</div>
								<div>
									{fighter.min_damage} - {fighter.max_damage}
								</div>
							</div>
							<Tooltip id="attack-tooltip" place="top" />
						</div>
					</div>
					<div className="w-1/2 border border-gray-550 text-m  rounded-sm">
						<div className="flex px-auto flex-grow text-gray-200 items-center">
							<div
								className={`flex mx-auto items-center space-x-1 ${vertical && 'p-2'}`}
								data-tooltip-id="defence-tooltip"
								data-tooltip-content="Defence"
							>
								<div>
									<DefenceIcon width={vertical ? 20 : 15} height={vertical ? 20 : 15} />
								</div>
								<div>{fighter.defence}</div>
							</div>
							<Tooltip id="defence-tooltip" place="top" />
						</div>
					</div>
				</div>
			)}

			<div
				className={`flex ${vertical ? 'flex-col w-full space-y-1' : 'space-x-1'}  w-full justify-between text-xxs md:text-sm font-bold`}
			>
				<div className="flex flex-grow text-gray-200 items-center border border-gray-550 rounded-sm">
					<div
						className="flex w-full items-center space-x-1 justify-between"
						data-tooltip-id="agility-tooltip"
						data-tooltip-content="Strength"
					>
						<div
							className={`flex flex-grow items-center ${vertical ? ' p-2' : 'justify-center'} space-x-1`}
						>
							<div>
								<StrengthIcon width={vertical ? 20 : 15} height={vertical ? 20 : 15} />
							</div>
							{vertical && <div>Strength: </div>}
							<div className={`${vertical && 'text-yellow-550'}`}>{fighter.strength}</div>
						</div>

						{updateable && fighter.available_stats > 0 && (
							<div className={`${vertical && 'p-2'}`}>
								<PlusIcon
									className="cursor-pointer"
									width={vertical ? 20 : 15}
									height={vertical ? 20 : 15}
									onClick={handleIncrementStrength}
								/>
							</div>
						)}
					</div>
					{!vertical && <Tooltip id="agility-tooltip" place="top" />}
				</div>
				<div className="flex flex-grow text-gray-200 items-center border border-gray-550 rounded-sm">
					<div
						className="flex w-full items-center space-x-1 justify-between"
						data-tooltip-id="agility-tooltip"
						data-tooltip-content="Agility"
					>
						<div
							className={`flex flex-grow items-center ${vertical ? ' p-2' : 'justify-center'} space-x-1`}
						>
							<div>
								<AgilityIcon width={vertical ? 20 : 15} height={vertical ? 20 : 15} />
							</div>
							{vertical && <div>Agility: </div>}
							<div className={`${vertical && 'text-yellow-550'}`}>{fighter.agility}</div>
						</div>

						{updateable && fighter.available_stats > 0 && (
							<div className={`${vertical && 'p-2'}`}>
								<PlusIcon
									className="cursor-pointer"
									width={vertical ? 20 : 15}
									height={vertical ? 20 : 15}
									onClick={handleIncrementAgility}
								/>
							</div>
						)}
					</div>
					{!vertical && <Tooltip id="agility-tooltip" place="top" />}
				</div>

				<div className="flex flex-grow text-gray-200 items-center border border-gray-550 rounded-sm">
					<div
						className="flex w-full items-center space-x-1 justify-between"
						data-tooltip-id="intelligence-tooltip"
						data-tooltip-content="Intelligence"
					>
						<div
							className={`flex flex-grow items-center ${vertical ? ' p-2' : 'justify-center'} space-x-1`}
						>
							<div>
								<IntelligenceIcon width={vertical ? 20 : 15} height={vertical ? 20 : 15} />
							</div>
							{vertical && <div className="font-basic">Intelligence: </div>}
							<div className={`${vertical && 'text-yellow-550'}`}>{fighter.intelligence}</div>
						</div>

						{updateable && fighter.available_stats > 0 && (
							<div className={`${vertical && 'p-2'}`}>
								<PlusIcon
									className="cursor-pointer"
									width={vertical ? 20 : 15}
									height={vertical ? 20 : 15}
									onClick={handleIncrementIntelligence}
								/>
							</div>
						)}
					</div>
					{!vertical && <Tooltip id="intelligence-tooltip" place="top" />}
				</div>

				<div className="flex flex-grow text-gray-200 items-center border border-gray-550 rounded-sm">
					<div
						className="flex w-full items-center space-x-1 justify-between"
						data-tooltip-id="vitality-tooltip"
						data-tooltip-content="Vitality"
					>
						<div
							className={`flex flex-grow items-center ${vertical ? ' p-2' : 'justify-center'} space-x-1`}
						>
							<div>
								<VitalityIcon width={vertical ? 20 : 15} height={vertical ? 20 : 15} />
							</div>
							{vertical && <div>Vitality: </div>}
							<div className={`${vertical && 'text-yellow-550'}`}>{fighter.vitality}</div>
						</div>

						{updateable && fighter.available_stats > 0 && (
							<div className={`${vertical && 'p-2'}`}>
								<PlusIcon
									className="cursor-pointer"
									width={vertical ? 20 : 15}
									height={vertical ? 20 : 15}
									onClick={handleIncrementVitality}
								/>
							</div>
						)}
					</div>
					{!vertical && <Tooltip id="vitality-tooltip" place="top" />}
				</div>
			</div>
		</div>
	);
};

export default FighterStats;
