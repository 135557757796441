import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Combat, Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';

import { RoundTargetHits } from '../Combat/RoundTargetHits';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';
import { ReactComponent as HealthIcon } from '../../assets/icons/svg/health.svg';

type CombatAbandonedProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	combat: Combat;
};

export const CombatAbandoned = forwardRef<HTMLDivElement, CombatAbandonedProps>(({ combat, ...rest }, ref) => {
	const navigate = useNavigate();

	const handleReturnToLobby = () => {
		navigate('/profile'); // Navigate to /lobby when the button is clicked
	};

	return (
		<div
			className="w-full h-full p-1 md:p-8 bg-cover bg-center border border-gray-550 rounded-sm"
			style={{
				backgroundImage: "url('/assets/--combat-abandonned-bg.png')",
			}}
		>
			<div className="flex flex-col h-full justify-between">
				<div className="flex flex-col items-center justify-center flex-grow">
					{' '}
					{/* Add flex, justify-center, and items-center */}
					<div className="text-2xl text-center font-bold">Battle Abandoned</div>
				</div>

				<div className="mt-auto">
					<Button
						size="large"
						variant="black"
						onClick={handleReturnToLobby}
						className="mt-4 px-4 py-4 text-2xl"
					>
						RETURN HOME
					</Button>
				</div>
			</div>
		</div>
	);
});

CombatAbandoned.displayName = 'CombatAbandoned';
