// MarketHistoryRow.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { Button } from '../Button/Button';
import { Item, MarketItem, Fighter } from '../../store/types/fighterTypes';

import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';

type MarketHistoryRowProps = {
	marketItem: MarketItem;
	fighter: Fighter | null | undefined;
	handleUnlist?: (id: string) => void;
	onClick?: (e: any) => void;
};

export const MarketHistoryRow = forwardRef<HTMLDivElement, MarketHistoryRowProps>(
	({ marketItem, fighter, handleUnlist, onClick, ...rest }, ref) => {
		return (
			<tr className="text-white  text-xxxs sm:text-xs  font-regular bg-gray-550 bg-opacity-50 border-t border-black">
				<td className="text-left p-2">
					<div className="flex items-center space-x-2 min-w-[200px]">
						<div className="w-[50px] sm:w-[96px]">
							<ItemThumbnail width={96} height={144} onClick={onClick} item={marketItem.item} />
						</div>
						<div>
							<div
								className={classNames(
									`font-bold text-xxs sm:text-m ${(marketItem.item.has_luck || marketItem.item.has_skill) && 'text-blue-550'}`
								)}
							>
								{marketItem.item.name}
								{marketItem.item.level_upgradeable && ` +${marketItem.item.level}`}
								{marketItem.item.has_skill && ` +Skill`}
								{marketItem.item.has_luck && ` +Luck`}
							</div>
							<div className="text-blue-550">{marketItem.item.category}</div>
						</div>
					</div>
				</td>
				<td className="text-left px-2 text-gray-200 w-[200px]">
					{formatTimestampToLocaleTime(marketItem.listed_at)}
				</td>
				<td className="text-left px-2 text-gray-200 w-[200px]">
					{marketItem.owner_address == fighter?.user_address ? (
						<span className="text-blue-550">SOLD</span>
					) : (
						<span className="text-red-550">BOUGHT</span>
					)}
				</td>
				<td className="text-left px-2 w-[120px] space-x-2">
					<div className="flex space-x-1">
						<div className=" self-end font-bold">
							{marketItem.owner_address.toLowerCase() == fighter?.user_address?.toLowerCase()
								? marketItem.buyer_name
								: marketItem.owner_name}
						</div>
					</div>
				</td>
				<td className="text-right px-2 w-[50px]">
					<div className="flex space-x-1 items-center">
						<div>
							<CreditsIcon />
						</div>
						<div className="font-bold text-blue-550 ">{formatNumLocale(marketItem.price, 0)}</div>
					</div>
				</td>
			</tr>
		);
	}
);

const formatNumLocale = (price: number, decimals: number): string => {
	return new Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(price);
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		day: '2-digit',
		month: '2-digit',
		year: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

MarketHistoryRow.displayName = 'MarketHistoryRow';
