// Pagination.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import { ReactComponent as BeginningIcon } from '../../assets/icons/svg/beginning.svg';
import { ReactComponent as PreviousIcon } from '../../assets/icons/svg/previous.svg';
import { ReactComponent as NextIcon } from '../../assets/icons/svg/next.svg';
import { ReactComponent as EndIcon } from '../../assets/icons/svg/end.svg';

type PaginationProps = {
	totalPages: number;
	currentPage: number;
	onChange: (page: number) => void;
};

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
	({ totalPages, currentPage, onChange, ...rest }, ref) => {
		return (
			<div className="mx-auto items-center text-gray-200 flex w-full justify-center space-x-2 mt-4 select-none">
				<div
					className={classNames({
						'text-white cursor-pointer': currentPage > 1,
					})}
				>
					<BeginningIcon
						onClick={() => {
							if (currentPage > 1) {
								onChange(1);
							}
						}}
					/>
				</div>
				<div
					className={classNames({
						'text-white cursor-pointer': currentPage > 1,
					})}
				>
					<PreviousIcon
						onClick={() => {
							if (currentPage > 1) {
								onChange(currentPage - 1);
							}
						}}
					/>
				</div>
				<div className="text-gray-200 text-sm">
					Page {currentPage} of {totalPages == 0 ? 1 : totalPages}
				</div>
				<div
					className={classNames({
						'text-white cursor-pointer': currentPage < totalPages,
					})}
				>
					<NextIcon
						onClick={() => {
							if (currentPage < totalPages) {
								onChange(currentPage + 1);
							}
						}}
					/>
				</div>
				<div
					className={classNames({
						'text-white cursor-pointer': currentPage < totalPages,
					})}
				>
					<EndIcon
						onClick={() => {
							if (currentPage < totalPages) {
								onChange(totalPages);
							}
						}}
					/>
				</div>
			</div>
		);
	}
);

Pagination.displayName = 'Pagination';
