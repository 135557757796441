// src/pages/CombatPage.tsx
import React, { useEffect, useState } from 'react';
import { eventCloud } from '../eventCloud';
import CombatNarrow from '../components/Combat/CombatNarrow';
import CombatWide from '../components/Combat/CombatWide';
import { Fighter, Combat, Round } from '../store/types/fighterTypes';
import { getBackendUrl } from '../helpers/getBackendUrl';
import { useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

type CombatPageProps = {
	fighter: Fighter | null;
};

const CombatPage: React.FC<CombatPageProps> = ({ fighter }) => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string | undefined }>();
	const [combat, setCombat] = useState<Combat | null>(null);
	const [closedRound, setClosedRound] = useState<Round | null>(null);
	console.log('CombatPage', id);
	useEffect(() => {
		if (!id || id === '0') {
			navigate('/profile');
		}
	}, [id]);

	useEffect(() => {
		console.log('[Effect Run] id:', id, 'combat:', combat);
		if (!combat && id && id != '0') {
			console.log('[combatsSub] fetchCombat', id);
			fetch(getBackendUrl() + '/combat?id=' + id)
				.then((response) => {
					if (!response.ok) {
						throw new Error('Failed to fetch combat');
					}
					return response.json();
				})
				.then((data: any) => {
					console.log('[fetchCombat]', data);
					if (!data.combat) {
						navigate('/profile');
					}
					setCombat(data.combat);
				})
				.catch((error) => {
					console.error('Error fetching combat:', error);
				});
		}
	}, [combat, id]);

	useEffect(() => {
		const handleCombatUpdate = (newCombat: Combat) => {
			//setHitScreenVisible(false);
			console.log('[handleCombatUpdate] ', newCombat, combat);
			// Redirect to the combat page
			if (newCombat.id == id) {
				if (combat && newCombat.closed_rounds.length > combat.closed_rounds?.length) {
					// round closed
					console.log('set clsoed round');
					setClosedRound(newCombat.closed_rounds[newCombat.closed_rounds.length - 1]);
					//setHitScreenVisible(true);
				}
				setCombat(newCombat);
			}
		};

		// Attach the event listener
		eventCloud.on('combat_update', handleCombatUpdate);

		// Cleanup the event listener on unmount
		return () => {
			eventCloud.off('combat_update', handleCombatUpdate);
		};
	}, [combat]);

	if (!id) {
		return <div>Combat ID is missing.</div>;
	}

	const handleSendAttack = (selectedAttack: string | null, selectedDefence: string | null, selectedSkill: string) => {
		console.log(
			`[handleSendAttack] selectedAttack=${selectedAttack} selectedDefence=${selectedDefence} selectedSkill=${selectedSkill}`
		);
		if (!combat) {
			console.error('Combat ID is undefined');
			return;
		}

		if (!selectedAttack) {
			eventCloud.emit('highlight_selectors_attack');
		}

		if (!selectedDefence) {
			eventCloud.emit('highlight_selectors_defence');
		}

		if (!selectedAttack || !selectedDefence) {
			toast.error('Select Attack/Defence region on the avatars.', {
				position: 'top-left',
				autoClose: 1000,
				hideProgressBar: true,
				closeOnClick: true,
				progress: undefined,
				theme: 'dark',
			});
		}

		//toast.warn("Select Attack/Defence region")

		if (!selectedAttack || !selectedDefence) {
			return;
		}

		// Send the attack action to the backend using the sendAttack method
		eventCloud.sendAttack(combat.id, selectedAttack, selectedDefence, selectedSkill);
	};

	return (
		<div className="flex flex-col flex-grow h-full">
			<div className="block bs:hidden h-full flex-grow flex flex-col">
				<CombatNarrow
					fighter={fighter}
					combat={combat}
					closedRound={closedRound}
					handleSendAttack={handleSendAttack}
				/>
			</div>
			<div className="hidden bs:block flex-grow flex flex-col">
				<CombatWide
					fighter={fighter}
					combat={combat}
					closedRound={closedRound}
					handleSendAttack={handleSendAttack}
				/>
			</div>
		</div>
	);
};

export default CombatPage;
